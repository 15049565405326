import * as target from "./target";
import * as tdo from "./tdo";
// import * as atperf from "./atperf";
import * as utilities from "./utilities";

( function () {
	
	// An indicator for build_local mappings 
	console.log( '%c' + LOG_PREFIX + ' -- Local Build Mapping: ' + CODE_VERSION + ' | 2025-03-11T18:44:44.582Z', 'background: orange; color: white; display: block; line-height:20px; padding:5px 30px; border: 1px solid red; font-size:1em;' );

	window._aape = ( window._aape ? _aape : {} );
	
	window._tt = { "utils": {}, "META": [], "TDO": tdo.init() };
	window._tt.deployment = {
		version: CODE_VERSION,
		ts: '2025-03-11T18:44:44.582Z'
	};
	
	window._tt.utils.sendTargetData = tdo.sendTargetData;

	_tt.mboxEnabled = ( function() { // defined here and also utilized in the third-parties deployement

			let isCashstar = window.location.host.indexOf( ".cashstar.com" ) !== -1;

			return ( !utilities.isConsumerApp() || ( utilities.isConsumerApp && !isCashstar ) );

	}() );

	let initializeLaunchSequence = function() {

		const MAX_POLL_COUNT = 20;
		// atperf.init();

		( function() {

			let alloyPoll;
			let pollCount = 0;

			/**
			 * Checks if the `window.alloy` object is available and initializes the `target` module.
			 * @function checkAlloy
			 */
			const checkAlloy = function() {

				if ( window.alloy ) {

					clearInterval( alloyPoll );
					target.init();

				} else if ( pollCount >= MAX_POLL_COUNT ) {

					clearInterval( alloyPoll );

				}

				pollCount = pollCount + 1;

			};

			if ( window.alloy ) {

				target.init();

			}
			else {

				alloyPoll = setInterval( checkAlloy, 100 );

			}

		} )();

	}

	let checkPrerenderState = function() {

		_aape.pageState = "";

		if ( document.prerendering ) {

			_aape.pageState = utilities.stringAppendWithDelimiter( _aape.pageState, "prerendering", ">" );

			document.addEventListener(
				'prerenderingchange',
				function() {
					console.log( LOG_PREFIX + " -- window.preRenderState: ", window.preRenderState );
					console.log( LOG_PREFIX + " -- document.prerendering: ", document.prerendering );
					// TODO: reenable initializeLaunchSequence from this postion when fully turrning on prerendering support
					// initializeLaunchSequence();
					_aape.pageState = utilities.stringAppendWithDelimiter( _aape.pageState, "activated | time to activation: " + self.performance?.getEntriesByType?.( 'navigation' )[ 0 ]?.activationStart, ">" );
				},
				{ once: true } );
		}
		else {
			console.log( LOG_PREFIX + " -- document.readyState: ", document.readyState );

			if ( !self.performance?.getEntriesByType?.( 'navigation' )[ 0 ]?.activationStart ) {
				_aape.pageState = utilities.stringAppendWithDelimiter( _aape.pageState, "no prerender", ">" );
			} else {
				_aape.pageState = utilities.stringAppendWithDelimiter( _aape.pageState, "prerendered and activated", ">" );
			}

			// TODO: reenable initializeLaunchSequence from this postion when fully turrning on prerendering support
			// initializeLaunchSequence();

		}

		// TODO: disable initializeLaunchSequence from this postion when fully turrning on prerendering support
		initializeLaunchSequence();

	};

	if ( _tt.mboxEnabled ) {

		checkPrerenderState();

	}

} )();
